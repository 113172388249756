import { useEffect, useMemo, useState} from "react";
import PageTitle from "../../../../../components/PageTitle";
import { useLocation } from "react-router-dom";
import { formatDate, formatTime } from "../../../../../utils/helper";
// import Table from "../../../../components/Table";
import "../index.css" ;
import { setLockDevice, viewLockDevice } from "../../../../../services/users";
import customToast from "../../../../../components/CustomToast";
import { IoMdLock } from "react-icons/io";
import { getSubscriptionDetails } from "../../../../../services/payment";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
const UserDevicesDetail = () => {
 
  const location = useLocation();

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [subsDetails, setSubsDetails] = useState(null);

  useEffect(() => {
    viewLockDevice({userId: location?.state?.userId})
    .then((res) => {
      setSelectedDevice(res?.data?.deviceId)
    })
    getSubscriptionDetails()
    .then((res) => {
      setSubsDetails(res.data)
    })
    .catch((err) => customToast.error("An unexpected error occured"))
  }, [location?.state?.userId])


  const logState = (event) => {
    if(event.target.checked === true ) {
      setSelectedDevice(event.target.id)
      setLockDevice({ userId: location?.state?.userId, deviceId:  event.target.id})
    } else {
      setSelectedDevice(null)
      setLockDevice({ userId: location?.state?.userId, deviceId:  null})
    }
  }

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title={`${location?.state?.firstName} ${location?.state?.lastName}'s Devices`} hierarchy={true} />

        <div className="admin-panel__alert m-5">
            <h2>PLEASE NOTE</h2>
            <p>
             Device lock feature is only applicable to mobile devices. Once locked the user cannot login from another mobile device, however he / she can login to webapp from any laptop / computer.
            </p>
          </div>

          <div className="d-flex flex-row gap-3 m-5">
          <div className="web-app__heading">Lock {location?.state?.firstName} {location?.state?.lastName}'s Device For A Single Device Login
          </div>  
            {
              (subsDetails?.length> 0 && subsDetails[0]?.deviceLock === true) === true ? null :
              <div className="ml-10" style={{color:"red", }}>{"(*Only Available in Advance Plan)"}</div>
            }  
           </div>   
             
        <div className="p-3 d-flex flex-row flex-wrap  align-items-center justify-content-center">   

          {location.state.devices.map((device, idx)=>
            <div className="mobile-holder mx-5" key={idx}>
              <div className="p-5 d-flex gap-5 flex-column align-items-start justify-content-evenly">

                {
                  (subsDetails?.length > 0 && subsDetails[0]?.deviceLock === true) === true
                   ? <div className="checkbox"> 
        
                  <div><input type="checkbox" 
                  className="custom-checkbox me-2"
                  id={device.uniqueString} 
                  onClick={logState} 
                  checked={selectedDevice === device.uniqueString}
                  /></div>

                  {/* {
                    (selectedDevice === device.uniqueString) === true ? 
                    (
                      <ImCheckboxChecked id={device.uniqueString}  className="icon-color-tertiary cursor-pointer" onClick={logState}/>
                    ):(
                      <ImCheckboxUnchecked id={device.uniqueString}  className="icon-color-tertiary cursor-pointer" onClick={logState}/>
                    )
                  } */}
  
                   {
                    selectedDevice === device.uniqueString && (
                      <>
                      <h2 className="web-app__heading mt-2">Locked</h2>
                      <div>
                      <IoMdLock size={22}/>
                      </div>
                      </>
                    )
                  }
                  </div> :
                  <ImCheckboxUnchecked style={{color:"grey", cursor:"not-allowed", marginTop:"12px"}} size={22} />
                }

                <span className="details-container">Name: <span className="info-cont">{device.deviceName}</span></span>
                <span className="details-container" >Brand: <span className="info-cont">{device.brand}</span></span>
                <span className="details-container" >Manufacturer: <span className="info-cont">{device.manufacturer}</span></span>
                <span className="details-container" >Device Year Class: <span className="info-cont">{device.deviceYearClass}</span></span>
                <span className="details-container" >App Version: <span className="info-cont">{device.medorn}</span></span>
                <span className="details-container" >First Login Date: <span className="info-cont">{formatDate(new Date(device.createdAt))}</span></span>
                <span className="details-container" >Last Login Date: <span className="info-cont">{formatDate(new Date(device.updatedAt))}</span></span>
                <span className="details-container" >Last Login Time: <span className="info-cont">{formatTime(new Date(device.updatedAt))}</span></span>
              </div>
            </div>
          )}
          
        </div>
        
      </div>
    </div>
  );
};

export default UserDevicesDetail;
