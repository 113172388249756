import { useState, useEffect, useMemo, useRef } from "react";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import Select from "react-select";
import { dcrStyles } from "../../../public/stylesheets/selectStyles";
import { removeEntityOption, viewEntityOptions } from "../../../../services/entityOptions";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

const DoctorControls = () => {
  const [type, setType] = useState();
  const [data, setData] = useState();

  const typeOptions = [
    { label: "Specialization", value: "specialization" },
    { label: "Degree", value: "degree" },
    { label: "Category", value: "category" },
  ];


  const handleCheckboxClick = async (data) => {
    try {
      await removeEntityOption({type: type?.label, optionId: data?._id, enabled: !data?.isEnabled})
    switch (type?.value) {
        case "specialization":
          const specializationData = await viewEntityOptions({type: "Specialization", allOptions: "true"});
          if (specializationData) {
            setData(specializationData.data);
          }
          break;
        case "degree":
          const degreeData = await viewEntityOptions({type: "Degree", allOptions: "true"});
          if (degreeData) {
            setData(degreeData.data);
          }
          break;
        case "category":
          const categoryData = await viewEntityOptions({type: "Category", allOptions: "true"});
          if (categoryData) {
            setData(categoryData.data);
          }
          break;
      }
    } catch (error) {
      customToast.error("An unexpected error occured!")
    }
  }

  const handleSubmit = async (type) => {
    // e.preventDefault();
    if (!type) {
      customToast.error("Please select type");
      return;
    }
    switch (type?.value) {
      case "specialization":
        const specializationData = await viewEntityOptions({type: "Specialization", allOptions: "true"});
        if (specializationData) {
          setData(specializationData.data);
        }
        break;
      case "degree":
        const degreeData = await viewEntityOptions({type: "Degree", allOptions: "true"});
        if (degreeData) {
          setData(degreeData.data);
        }
        break;
      case "category":
        const categoryData = await viewEntityOptions({type: "Category", allOptions: "true"});
        if (categoryData) {
          setData(categoryData.data);
        }
        break;
    }
  };

  const columns = useMemo(() =>  [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: "",
        Cell: (props) => {

           return props?.row?.original?.isEnabled === true ? (
                <ImCheckboxChecked 
                className="icon-color-tertiary cursor-pointer"
                onClick={(e) => {
                    e.preventDefault();
                    handleCheckboxClick(props?.row?.original);
                  }}
                />
              ) : (
                <ImCheckboxUnchecked
                className="icon-color-tertiary cursor-pointer"
                onClick={(e) => {
                    e.preventDefault();
                    handleCheckboxClick(props?.row?.original);
                  }}
                />
              );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
     [type]);

  const tableData = useMemo(() => serialise(data), [data]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content ">
        <PageTitle title="Doctor Controls" />
        <section className="">
          <form onSubmit={handleSubmit}>
            <div className="util-list-filters d-flex justify-content-between">
              <div className="d-flex flex-column">
                {/* <div className="admin-panel__alert my-3">
                  <h2>PLEASE NOTE</h2>
                  <p>
                  MR gps tracking data will be available for 3 months. After that, it will be permanently deleted. 
                  Any deleted data will be completely removed from our system after 6 months.
                  Please make sure to save any important information within these times.
                  </p>
                </div> */}
                <div className="d-flex">
                  <div className="util-tp-filter me-5">
                    <p className="mb-3">
                      Select Type <span className="asterisk-imp">*</span>
                    </p>
                    <Select
                      styles={dcrStyles}
                      placeholder="Select Type"
                      options={typeOptions}
                      value={type}
                      onChange={(selectedOption) => {
                        setType(selectedOption)
                        setData([]);
                        handleSubmit(selectedOption)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
      <section className=" table-container" style={{ marginRight: "2rem" }}>
        <div className="filter-table">
          {data?.length > 0 && (
            <h2 className="web-app__heading mb-4 ps-3">
              Showing ({data?.length}) Entries
            </h2>)
          }
          {tableData?.length > 0 ? (
            <Table columns={columns} data={tableData} />
          ) : (
            <h3 className="mt-2 no-results-message">No data results found</h3>
          )}
        </div>
      </section>
    </main>
  );
};

export default DoctorControls;