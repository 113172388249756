import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import rocket from '../../../assets/rocket.png'
import { loginUser } from "../../../reducers/userReducer";
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined,StarFilled } from '@ant-design/icons';
import { Tooltip, Input, Space } from 'antd';
import {
  SectionLogin,
  BtnBlue,
  LoginLeft,
  LoginRight,
  LoginImgInner,
  LoginImgOuter,
} from "../../public/stylesheets/authentication.js";
import InnerDots from "../../public/img/inner-dots.png";
import OuterDots from "../../public/img/outer-dots.png";
import customToast from "../../../components/CustomToast";
import { ToastContainer } from "react-toastify";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaLocationDot, FaMoneyCheckDollar } from "react-icons/fa6";

const Login = ({ loggedUser }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    if (loggedUser) navigate("/dashboard");

    // Prefill from localStorage
    const storedSubdomain = localStorage.getItem("subdomain");
    const storedEmail = localStorage.getItem("email");

    if (storedSubdomain) setSubdomain(storedSubdomain);
    if (storedEmail) setEmail(storedEmail);

  }, [loggedUser, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    if(loading)return customToast.error("Trying to login. Please Wait!");
    if (email && password && subdomain) {
      let error = null;

      try {
        setLoading(true);
        await dispatch(loginUser({ username:email.toLowerCase().trim(), password, subdomain:subdomain.toLowerCase().trim() }, remember));
      } catch (e) {
        error = e;
        customToast.error(error?.response?.data.message);
      }finally{
        setLoading(false);
      }

      if (!error) {

        // Save subdomain and email to localStorage
        localStorage.setItem("subdomain", subdomain);
        localStorage.setItem("email", email);

        let from = location.state?.from?.pathname || "/";
        navigate(from, { replace: true });

        customToast.success("Successfully Logged In");
      }
    }else customToast.error("Please fill all fields");
  };

  return (
    <main>
      <SectionLogin>
        <div className="">
          <div className="row">
            <LoginLeft className="col-md-6">
              <h1>Medorn</h1>
              <h2>What's New <img src={rocket}  width={35} height={35}/></h2>
              <p>Geo Tagging <FaLocationDot className="icon-color-green"/></p>
              <span>Medorn ensures that the reporting done is with respect to the location but also ensures that the privacy of users is maintained.</span>
              <p>Approval System <IoMdCheckmarkCircleOutline  className="icon-color-green" /></p>
              <span>The hierarchy is very strictly maintained in the App. Every things that requires attention goes one level up for approval.</span>
              <p>Automated Expense <FaMoneyCheckDollar className="icon-color-green" /></p>
              <span>No need to fill the expense manually. It generates an automated expense based on the reporting wherein user can rectify if anything is wrong.</span>
             
              <LoginImgInner src={InnerDots} alt="Inner Dots" />
            </LoginLeft>

            <LoginRight className="col-md-6 min-h-34">
              <p>
                Welcome Back
              </p>
              <br />
                
              <Space direction="vertical" className="w-100">
                <Tooltip placement="topLeft" title="Enter Company Id">
                  <Input size="large" placeholder="Enter Company Id" 
                    onChange={(e) => setSubdomain(e.target.value)}
                    value={subdomain}
                    suffix={<StarFilled />} 
                  />
                </Tooltip>
                <br/>
                <Tooltip placement="topLeft" title=" Enter Email">
                  <Input size="large" placeholder="Enter Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  suffix={<UserOutlined />} 
                  />
                </Tooltip>
                <br />
                <Tooltip placement="topLeft" title=" Enter Password">
                  <Input.Password
                    placeholder="Enter password"
                    size='large'
                    onChange={(e) =>setPassword(e.target.value)}
                    onKeyDown={(e)=> {
                      if(e.key === 'Enter')handleLogin(e)
                    }}
                    value={password}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Tooltip>
              </Space>
              <BtnBlue type="button" onClick={handleLogin} className="mt-5 rounded-full">
                Log In
              </BtnBlue>
            </LoginRight>
          </div>
        </div>
      </SectionLogin>
      <LoginImgOuter src={OuterDots} alt="Dots" />

      <ToastContainer />
    </main>
  );
};

export default Login;
