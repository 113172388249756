import { MdArrowBackIosNew } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useMemo, useCallback } from "react";
import Table from "../../../../../../components/Table";
import serialise from "../../../../../../utils/serialiseResponse";
import { viewProdWiseReportDetails } from "../../../../../../services/analytics";
import customToast from "../../../../../../components/CustomToast";
import { formatDate } from "../../../../../../utils/helper";

const ProdWiseSalesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const id = location?.state?.id ;
  const prodId = location?.state?.prodId;
  const month = location?.state?.month ;
  const year = location?.state?.year ;
  const type = location?.state?.type ;
  const [loading,setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  
  useEffect(()=>{
    const fetch = async () => {
      if(loading)return ;
      setLoading(true);
      let filter = {
        prodId:prodId,
        id:id,
        month: month?.value,
        year: year?.label,
        filterType:type?.label
      };
      try{
        const {data} = await viewProdWiseReportDetails(filter);
        setReports(data);
      }catch(err){
        customToast.error("Error Please go back to previous page")
      }
      setLoading(false);
    }
    fetch();
  },[]);
  
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters: true,
      // },
      {
        Header: "Date",
        accessor:"date",
        disableFilters:true,
        minWidth:80 ,
        maxWidth:80 ,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },      
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        showTotal:true,
        totalAccessor:"totalQuantity",
        disableFilters: true,
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"totalValue",
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      }
    ],
    []
  );
  const columnsSTK = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   disableFilters: true,
      //   maxWidth:50
      // },
      {
        Header: "Date",
        accessor:"date",
        disableFilters:true,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header:"Stockist",
        accessor:"stockist",
      },
      {
        Header:"Headquarter",
        accessor:"headquarter",
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"totalQuantity"
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"totalValue",
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      },
    ],
    []
  );
  const columnsHQ = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   disableFilters: true,
      //   maxWidth:50
      // },
      {
        Header: "Date",
        accessor:"date",
        minWidth:100 ,
        disableFilters:true,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header:"Headquarter",
        accessor:"headquarter",
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"totalQuantity"
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"totalValue",
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(reports), [reports]);

  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Product Wise Primary Sales Detail
          </span>
        </h2>

        <div className="filter-table">
          { loading && <h3 className="mt-2 fetching-results-message">Hold on fetching reports for you...</h3>}
          {tableData.length > 0 && (
            <>
              <h2 className="web-app__heading mb-4 ps-3 d-flex justify-content-between">
                Showing ({reports.length}) Entries
              </h2>
              {type.label === "Product" && <Table columns={columns} data={tableData} fileSrc="Product wise sales detail (Product)" />}
              {type.label === "Stockist" && <Table columns={columnsSTK} data={tableData} fileSrc="Product wise sales detail (Stockist)" />}
              {type.label === "Headquarter" && <Table columns={columnsHQ} data={tableData} fileSrc="Product wise sales detail (Headquarter)" />}
            </>
            ) 
          }
          {
          !loading && tableData.length === 0 && (<h3 className="mt-2 no-results-message">No results found</h3>)
          }
        </div>
      </div>
    </div>
  );
};

export default ProdWiseSalesDetail;
