import { useEffect, useMemo, useState} from "react";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { getUserDevices } from "../../../../services/users";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import customToast from "../../../../components/CustomToast";

const UserDevices = () => {
 
  const [users, setUsers] = useState([]);

  useEffect(()=>{
    getUserDevices().then(data=>{
      // customToast.success(data.message);
      setUsers(data.data);
    }).catch(err=>{
      customToast.error("Sorry Something went wrong");
    })
  },[]);
  
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        Cell: (props) => {
          return (
            <span>{`${props?.row?.original.firstName} ${props?.row?.original.lastName}`}</span>
          );
        },
      },
      {
        Header: "Devices",
        accessor: "device",
        disableFilters: true,
        Cell: (props) => {
          return (
            <span>{props?.row?.original?.devices.length} </span>
          );
        },
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        Cell: (props) => {
          return (
            <Link
              to={`./${props?.row?.original?.userId}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    []
  );
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="User Controls" />

        <section className="area-creation-content__info">
          {users.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({users.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={users} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default UserDevices;
