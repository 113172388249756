import { useEffect, useState, useMemo, useCallback } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import { v4 as uuidv4 } from "uuid";
import {
  setTPForMonth,
  clearTourProgram,
} from "../../../../reducers/tpReducer";
import {
  clearHeadquarters,
  setHeadquartersForReporting,
} from "../../../../reducers/locations/hqReducer";
import {
  clearRoutes,
  setRoutesForReportingOnly,
} from "../../../../reducers/locations/routeReducer";
import { generateSelectData, generateSelectRoute, generateSelectUser } from "../../../../utils/serialiseResponse";
import { selectUserStyles, dcrStyles, adminStyles } from "../../../../views/public/stylesheets/selectStyles.js";
import {
  activityOptions,
  areaTypeOptions,
  monthOptions,
  yearOptions
} from "../../../../utils/helper";
import { MdOutlineGroup } from "react-icons/md";
import { viewUsersForUtilities,viewMedRepresentatives } from "../../../../services/utilities";
import TP from "../SingleDayTP";
import PageTitle from "../../../../components/PageTitle/index.jsx";
import { viewUsersInHeirarchy } from "../../../../services/users.js";
import { editTP, newTP, newTPAdmin, viewTPForDate } from "../../../../services/tourProgram.js";
import moment from "moment-timezone";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";


const TourForm = () => {
  const dispatch = useDispatch();
  const tpData = useSelector(({ tp }) => tp);
  const loggedIn = useSelector(({ user }) => user);
  const tpDeadline = useSelector((state) => state.settings["tp-deadline"]);
  const tourlimit = useSelector((state) => state.settings["tourlimit"]);
  const [month, setMonth] = useState();
  const [year, setYear] = useState(yearOptions[2]);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const sundayWorking = useSelector((state) => state.settings["sundayWorking"]);
  const [date, setDate] = useState(new Date());
  const [isSection, SetIsSection] = useState(false);
  const [activityType, setActivityType] = useState({ value: 1, label: "Working" });
  const [areaType, setAreaType] = useState();
  const [workAreas, setWorkAreas] = useState([]);
  const [remark, setRemark] = useState("");
  const [coWorkers, setCoWorkers] = useState([]);
  const [workingWith, setWorkingWith] = useState([]);
  const [hq, setHq] = useState();
  const [tData, setTdata] = useState()
  
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const routes = useSelector(({ route }) => route);
  const routeSelect = useMemo(() => generateSelectRoute(routes.data), [routes]);
  // let filteredRoutes = routeSelect.filter((el) => el?.type === areaType?.label);
  let filteredRoutes = [];
   filteredRoutes = routeSelect.filter((el) => {
    if (areaType?.value === 4 && el?.type === 'Ex-Station' && el?.headquarter === hq?.value) return true;
    if (areaType?.value === 5) return el.type === 'Out-Station';
    return el?.type === areaType?.label
  });
  let newRoutes = filteredRoutes.filter((el) => {
    if (el?.headquarter === loggedIn?.user?.headquarter) return el;
  });

  let hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  if(areaType?.value === 4){
    hqSelect =  hqSelect.filter((e) => !e.headquarter)
  }

  // const filteredHqRoutes = routeSelect.filter((el) => el?.headquarter === hq?._id)

  useEffect(() => {
    dispatch(setHeadquartersForReporting({ withWorkAreas: true }));
    

    return () => {
      dispatch(clearHeadquarters());
      dispatch(clearRoutes());
      dispatch(clearTourProgram());
    };
  }, [dispatch]);
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
      // viewMedRepresentatives({includeMe:true}).then((res) => {
      //   setEmployees([...res.data]);
      // });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let formattedDate = moment
      .tz(date, "Asia/Kolkata")
      .format()
      .toString()
      .substring(0, 10);

    // viewTPForDate({ date: formattedDate }).then((res) => {
    //   setTdata(res.data);
    // })

    let tpForDate = tpData?.data?.filter((e) => formattedDate.toString() === e.dateString)
    setTdata(tpForDate[0])
    setRemark(tpForDate[0]?.remarks)
    const matchingArea = areaTypeOptions.find(
      option => option.label === tpForDate[0]?.areaType
    );
    if (matchingArea) {
      setAreaType(matchingArea);
    } else {
      setAreaType()
    }
    const matchingActivity = activityOptions.find(
      option => option.label === tpForDate[0]?.activity
    );
    if (matchingActivity) {
      setActivityType(matchingActivity);
    } else {
      setActivityType({ value: 1, label: "Working" })
    }
    // setAreaType(tpForDate[0]?.areaType)
    // setActivityType(tpForDate[0]?.activity)
    let transformedWorkedWith = tpForDate[0]?.workedWith?.map(e =>   ({label: `${e["firstName"]} ${e["lastName"]}`,
      value: e._id,
      designation: e?.des,}))
    setWorkingWith(transformedWorkedWith)

    let transformedWorkAreas = tpForDate[0]?.workAreas?.map(e => e.location)
    setWorkAreas(transformedWorkAreas)
    
  },[date])

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const coWorkerSelect = useMemo(
    () => generateSelectUser(coWorkers, "fullName"),
    [coWorkers]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    let date = new Date().toISOString();
    if(date > tpDeadline) return customToast.error("TP cannot be submitted - deadline has passed");
    let error = false;
    try {
      dispatch(setRoutesForReportingOnly({month:month?.value , year:year?.label, adminTourLimit: tourlimit }));
      await dispatch(setTPForMonth({ month: month?.value, year: year?.label }));
      resetFrom()
      viewUsersInHeirarchy().then((res) => {
        setCoWorkers([...res.data])
      })
    } catch (err) {
      customToast.error("Error occured please try again");
      error = true;
    }
    if (!error) {
      customToast.success("Request submitted Successfully");
    }
  };

  const resetFrom = () => {
    setDate(new Date())
    setActivityType({ value: 1, label: "Working" })
    setAreaType()
    setWorkAreas([])
    // SetIsSection(false)
    setRemark("")
    setWorkingWith([])
  }
  
  useEffect(() => {
    if(!month || !year || !selectedUser)return ;
    dispatch(setTPForMonth({month:month?.value , year:year?.label, userId: selectedUser?.value }));
    dispatch(setRoutesForReportingOnly({month:month?.value , year:year?.label, userId: selectedUser?.value, adminTourLimit: tourlimit }));
    resetFrom()
    viewUsersInHeirarchy({userId: selectedUser?.value}).then((res) => {
      setCoWorkers([...res.data])
    })
  }, [selectedUser, month, year, dispatch, tourlimit]);

  // if(loggedIn?.user?.des < 121){
  //   areaTypeOptions.push(
  //     { value: 4, label: "Out-Ex-Station" },
  //     { value: 5, label: "Out-Station-Last-Day" },
  //   )
  // }

  const handleTpAdd = async(e) => {
    e.preventDefault()
    if (!areaType || workAreas.length === 0 ){
      return customToast.error("Please fill all the required details");
    }
    let finalWorkAreas = [];
    workAreas.forEach((el) => {
      let count = 1;
      finalWorkAreas.push({ id: count, location: el });
      count++;
    });
    let workedWith = workingWith?.map((e) => e.value)
    // const plan = [
    //   {
    //     month: Number(month?.value),
    //     year: Number(year?.label),
    //     cdt:new Date(tp?.dateString).getTime() || new Date().getTime(),
    //     date: new Date(tp?.dateString),
    //     dateString: tp?.dateString,
    //     isMonthlyTP: false ,
    //     areaType: areaType?.label,
    //     workAreas: finalWorkAreas,
    //     ID: uuidv4(),
    //   },
    // ];

    let plan = [
      {
        month: new Date(tData?.dateString).getMonth(),
        year: new Date(tData?.dateString).getFullYear(),
        date: new Date(tData?.dateString),
        cdt: Date.now(),
        dateString: tData?.dateString,
        isMonthlyTP: false,
        areaType: areaType?.label,
        workAreas: finalWorkAreas,
        remarks: remark,
        workedWith: workedWith || [],
        activity: activityType?.label,
        ID: uuidv4(),
      },
    ];
    try {
      if(loggedIn?.user?.isAdmin)await newTPAdmin({body:plan, empId: selectedUser?.value})
      else await newTP(plan);
     
      dispatch(setTPForMonth({month:month?.value , year:year?.label,  userId: selectedUser?.value }));
      if(loggedIn?.user?.isAdmin)customToast.success("TP Successfully created for holiday");
      else customToast.success("TP Successfully sent for approval");
    } catch (err) {
      console.error("Some error", err);
    }
    setWorkAreas([]);
    setAreaType(null);
    setWorkingWith([]);
    setRemark("");
  };

  const handleEditTP = async() => {
    if (!areaType || workAreas.length === 0 ){
      return customToast.error("Please fill all the required details");
    }
    let finalWorkAreas = [];
    workAreas.forEach((el) => {
      let count = 1;
      finalWorkAreas.push({ id: count, location: el });
      count++;
    });
    let workedWith = workingWith?.map((e) => e?.value)

    const plan = [
      {
        month: new Date(tData?.dateString).getMonth(),
        year: new Date(tData?.dateString).getFullYear(),
        cdt: Date.now(),
        date: new Date(tData?.dateString),
        dateString: tData?.dateString,
        isMonthlyTP: false ,
        areaType: areaType?.label,
        workAreas: finalWorkAreas,
        remarks: remark,
        workedWith: workedWith || [],
        activity: activityType?.label,
        ID: uuidv4(),
      },
    ];

    try {
      await editTP({finalArray:plan , id:tData?._id});
      dispatch(setTPForMonth({month:Number(month?.value) , year:Number(year?.label) }));
      customToast.success("TP Successfully sent for approval");
    } catch (err) {
      console.error("Some error", err);
    }
    setWorkAreas([]);
    setAreaType(null);
    setWorkingWith([]);
    setRemark("");
  };

  return (
    <form className="tp__content" onSubmit={handleSubmit}>
      <div className="row row-bg2">
        <div className="col-md-4 col-lg-3 util-tp-filter">
          <p className="mb-2">
            Select Month <span className="asterisk-imp">*</span>
          </p>
          <Select
            options={monthOptions}
            styles={dcrStyles}
            value={month}
            onChange={(e) => {
              setMonth({ ...e });
            }}
          />
        </div>
        <div className="col-md-4 col-lg-3 util-tp-filter">
          <p className="mb-2">
            Select Year <span className="asterisk-imp">*</span>
          </p>
          <Select
            styles={dcrStyles}
            options={yearOptions}
            placeholder="Select Year"
            value={year}
            onChange={(e) => setYear({ ...e })}
          />
        </div>
        <div className="col-md-4 col-lg-3 util-tp-filter">
          {loggedIn?.user?.isAdmin ? (
              <div>
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="mt-5 ms-auto button-blue-gradient"
                onClick={(e) => {
                  if (!month) return customToast.error("Please select a month");
                  else if (!year)
                    return customToast.error("Please select a year");
                  handleSubmit(e);
                }}
              >
                Submit
              </button>)
            }
        </div>
      </div>
      <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
        <h4>
          <span className="tp-indicator-9 me-2"></span>Approved
        </h4>
        <h4>
          <span className="tp-indicator-10 me-2"></span>Rejected
        </h4>
        <h4>
          <span className="tp-indicator-5 me-2"></span>Pending
        </h4>
        <h4>
          <span className="tp-indicator-3 me-2"></span>Leave
        </h4>
        { 
  tpData?.data?.length > 0 && (
    !isSection ? (
      <IoIosArrowDropdownCircle
        className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
        onClick={() => SetIsSection(true)}
      />
    ) : (
      <IoIosArrowDropupCircle
        className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
        onClick={() => SetIsSection(false)}
      />
    )
  )
}
      </div>

      {/* add tour program */}
      <main className="admin-content">
     
      <div className="">
      {/* <PageTitle title="Create Tour Program"  isDropup={isSection} isDropdown={!isSection} toggle={SetIsSection}/> */}
      {isSection &&
          <form onSubmit={handleTpAdd} >
            <div className="primarysales-filter">
              <div className="util-tp-filter">
              <p className="mb-2"> Date  <span className="asterisk-imp">*</span></p>
              <input
                type="date"
                className=""
                id="date-input"
                placeholder="Date"
                style={adminStyles}
                value={date}
                min={new Date(Number(year.label), month.value, 1).toISOString().slice(0, 10)}
                max={new Date(Number(year.label), month.value + 1, 0).toISOString().slice(0, 10)}
                onChange={({ target }) => setDate(target.value)}
              />
            </div>
              <div className="util-tp-filter">
              <p className="mb-2">Activity Type  <span className="asterisk-imp">*</span></p>
                <Select
                  name="activityType"
                  id="activityType"
                  value={activityType}
                  options={activityOptions}
                  onChange={(e) =>
                    setActivityType({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Activity Type"
                />
              </div>
              <div className="util-tp-filter">
              <p className="mb-2">Area Type  <span className="asterisk-imp">*</span></p>
                <Select
                  name="areaType"
                  id="areaType"
                  value={areaType}
                  options={loggedIn?.user?.des === 121 ?  [
                    { value: 1, label: "Local" },
                    { value: 2, label: "Ex-Station" },
                    { value: 3, label: "Out-Station" },
                  ] : areaTypeOptions}
                  onChange={(e) =>
                  {
                    setAreaType({ value: e.value, label: e.label })
                    setWorkAreas([])
                  }
                  }
                  styles={adminStyles}
                  placeholder="Select Area Type"
                />
              </div>
              {
                areaType?.value === 4 && (
                  <>
                  <div className="util-tp-filter">
                  <p className="mb-2">Out station Headquarter  <span className="asterisk-imp">*</span></p>
                  <Select
                      options={hqSelect}
                      styles={adminStyles}
                      placeholder="Select Headquarter"
                      value={hq}
                      onChange={(e) => setHq({...e})}
                    />
                </div>
                </>
                )
              }
              <div className="util-tp-filter">
              <p className="mb-2">Location  <span className="asterisk-imp">*</span></p>
                <Select
                    isMulti
                    options={areaType?.value === 1 ? hqSelect : filteredRoutes }
                    styles={adminStyles}
                    placeholder="Select Location"
                    value={workAreas}
                    onChange={(e) => setWorkAreas([...e])}
                    className="multi-select-dropdown d-flex justify-content-center"
                  />
              </div>
              {
                loggedIn?.user?.des !== 121 && <div className="util-tp-filter">
                <p className="mb-2">Working With</p>
                  <Select
                      isMulti
                      options={coWorkerSelect}
                      styles={adminStyles}
                      placeholder="Select Co-workers"
                      value={workingWith}
                      onChange={(e) => setWorkingWith([...e])}
                      className="multi-select-dropdown d-flex justify-content-center"
                    />
                </div>
              }
              <div className="util-tp-filter">
              <p className="mb-2">Remark</p>
              <input
                type="text"
                className=""
                id="remark"
                placeholder="Enter remark here"
                style={adminStyles}
                value={remark}
                onChange={({ target }) => setRemark(target.value)}
              />
            </div>
              {
                (sundayWorking === "false" && new Date(date)?.toLocaleDateString('en-US', { weekday: 'long' }) === "Sunday") ? (<div>
                  <button disabled className="button-disabled mt-5">
                    Sunday
                    {/* Add TP */}
                  </button>
                </div>)
                : ((tData && Object.keys(tData).length > 1) ? ( tData?.leave ? 
                  <div>
                <button disabled className="button-disabled mt-5">
                  leave
                  {/* Add TP */}
                </button>
              </div> : ( tData?.holiday ? <div>
                <button disabled className="button-disabled mt-5">
                  Holiday
                  {/* Add TP */}
                </button>
              </div> : (
                tData?.isApproved === 0 ? <div>
                <button disabled className="button-disabled mt-5">
                  Pending
                  {/* Add TP */}
                </button>
              </div> : <div>
                 <button type="button"  className="button-blue-gradient mt-5" onClick={(e) => handleEditTP(e) }>
                  Edit TP
                </button>
              </div>
              ))
                ) : <div>
                <button type="button" className="button-blue-gradient mt-5"
                 onClick={(e) => handleTpAdd(e) }>
                  Add TP
                </button>
              </div>)
              }
            </div>
          </form>
        }
        </div>
        </main>

      <div className="filter-table">
        <table className="primarysales-table">
          <tbody id="primarysales-data">
            <tr className="primarysales-table__header">
              <th>Sr no.</th>
              <th>Date</th>
              <th>Day</th>
              <th>Area Type</th>
              <th>Location</th>
              {/* <th>Actions</th> */}
            </tr>
            {
              tpData?.data?.map((tp,idx)=>{
                return (
                  <TP key={idx} tp={tp} month={month} year={year} user={selectedUser} isSundayWorking={sundayWorking}></TP>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default TourForm;
