import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import {
  dcrStyles,
} from "../../../public/stylesheets/selectStyles";
import { useCallback, useState, useMemo, useEffect } from "react";
import Table from "../../../../components/Table";
import { viewProdWiseSecReports } from "../../../../services/analytics";
import { FaEye } from "react-icons/fa";
import serialise from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";

const ProdWiseSecondarySales = () => {
  const navigate = useNavigate();

  const [month, setMonth] = useState();
  const [year, setYear] = useState(yearOptions[yearOptions.length-1]);
  const [loading,setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [type , setType] = useState({label:"Product"});
  const typeOptions = [{label:"Product"} , {label:"Stockist"} , {label:"Headquarter"}] ;


  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        showTotal:true,
        totalAccessor:"totalQuantity",
        disableFilters:true,
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        showTotal:true,
        totalAccessor:"totalValue",
        disableFilters:true,
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={props?.row?.original?._id}
              state={{ data: props?.row?.original?._id,id:props?.row?.original?._id, 
                prodId:props?.row?.original?.prodId,
                month:month, year:year, type:type
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [month,year,type]
  );
  const columnsSTK = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header:"Stockist",
        accessor:"stockist",
      },
      {
        Header:"Headquarter",
        accessor:"headquarter",
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        showTotal:true,
        totalAccessor:"totalQuantity",
        disableFilters:true,
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        showTotal:true,
        totalAccessor:"totalValue",
        disableFilters:true,
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={props?.row?.original?._id}
              state={{ data: props?.row?.original?._id,id:props?.row?.original?._id, 
                prodId:props?.row?.original?.prodId,
                month:month, year:year, type:type
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [month,year,type]
  );
  const columnsHQ = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header:"Headquarter",
        accessor:"headquarter",
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        showTotal:true,
        totalAccessor:"totalQuantity"
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        showTotal:true,
        totalAccessor:"totalValue",
        disableFilters:true,
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.totalValue).toFixed(2)}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={props?.row?.original?._id}
              state={{ data: props?.row?.original?._id,id:props?.row?.original?._id, 
                prodId:props?.row?.original?.prodId,
                month:month, year:year, type:type
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [month,year,type]
  );
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if(loading)return ;
      if(!month || !year)return customToast.error("Please select month and year") ;

      setLoading(true);
      let filter = {
        month: month?.value,
        year: year?.label,
        filterType:type?.label
      };
      try{
        const {data} = await viewProdWiseSecReports(filter);
        setReports(data);
      }catch(err){
        customToast.error("error occured please try again")
      }
      setLoading(false);
    },
    [month, year,loading,type]
  );

  
  const tableData = useMemo(() => serialise(reports), [reports]);
  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Product Wise Secondary Sales
          </span>
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="util-tp-query d-flex justify-content-between">
            <div className="d-flex">
              <div className="util-tp-filter me-5">
                <p>
                  Select Month <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={monthOptions}
                  value={month}
                  onChange={(e) => setMonth({ ...e })}
                />
              </div>

              <div className="util-tp-filter me-5">
                <p>
                  Select Year <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Year"
                  className="mt-3"
                  options={yearOptions}
                  value={year}
                  onChange={(e) => setYear({ ...e })}
                />
              </div>
              <div className="util-tp-filter me-4">
                <p>
                  Select Filter Type <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Type"
                  className="mt-3"
                  options={typeOptions}
                  value={type}
                  onChange={(e) => {
                    setType({...e});
                    setReports([]);   
                  }}
                />
              </div>
            </div>
            <div className="d-flex">
              <div className="util-tp-filter">
                <button type="submit" className="button-blue-gradient mt-5">
                  See Reports
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="filter-table">
          { loading && <h3 className="mt-2 fetching-results-message">Hold on fetching reports for you...</h3>}
          {tableData.length > 0 && (
            <>
              <h2 className="web-app__heading mb-4 ps-3 d-flex justify-content-between">
                Showing ({reports.length}) Entries
              </h2>
              {type.label === "Product" && <Table columns={columns} data={tableData} fileSrc="Product wise secondary sales (Product)" />}
              {type.label === "Stockist" && <Table columns={columnsSTK} data={tableData} fileSrc="Product wise secondary sales (Stockist)" />}
              {type.label === "Headquarter" && <Table columns={columnsHQ} data={tableData} fileSrc="Product wise secondary sales (Headquarter)" />}
            </>
            ) 
          }
          {
          !loading && tableData.length === 0 && (<h3 className="mt-2 no-results-message">No results found</h3>)
          }
        </div>
      </div>
    </div>
  );
};

export default ProdWiseSecondarySales;
