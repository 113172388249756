import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "./../../../../../components/CustomToast";
import { setCities } from "../../../../../reducers/locations/cityReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";
import {
  addStockist,
  addStockistForApproval,
  clearStockists,
  setAllStockists,
} from "../../../../../reducers/targets/stockist";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../../utils/serialiseResponse";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import PageTitle from "../../../../../components/PageTitle";
import { viewMedRepresentatives } from "../../../../../services/utilities";

const CreateStockist = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);

  const stockists = useSelector(({ stockist }) => stockist);

  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);
 
  const [businessName, setBusinessName] = useState("");
  const [name, setName] = useState("");
  const [certification, setCertification] = useState("");

  const [gstNumber, setGstNumber] = useState("");
  const [drugLicenseNumber, setDrugLicenseNumber] = useState("");
  const [drugExpiryNumber, setDrugExpiryNumber] = useState("");

  const [birthday, setBirthday] = useState("");
  const [stockistContact, setStockistContact] = useState("");

  const [address, setAddress] = useState("");

  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [fromCity, setFromCity] = useState();
 const [filterCities, setfilterCities] = useState({"data" : []})
 const [extraInfo, setExtraInfo] = useState("");

  // useEffect(() => {
  //   dispatch(setCities());
  //   try {
  //     viewUsersForUtilities().then((res) => {
  //       let data = res.data.filter((e) => e.des === 121)
        
  //       setEmployees([...data]);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
    
  //   return () => {
  //   };
  // }, [dispatch]);

  useEffect(() => {
    dispatch(setHeadquarters());
    return ()=> {dispatch(clearHeadquarters());}
  }, [dispatch]);

  useEffect(() => {
    if (!headquarter) return;
    let data = cities?.data?.filter((e) => {
      return e.headquarter._id === headquarter?.value
     
    })
    setfilterCities({"data": data});
    dispatch(setCities(headquarter?.value));
  }, [dispatch, headquarter]);


  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(
    () => generateSelectData(cities.data, "name"),
    [cities]
  );
  
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const tableData = useMemo(() => {
    return serialise(stockists?.data);
  }, [stockists?.data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !businessName || !headquarter || !city)
      return customToast.error("Please fill all the Required Fields");

      var index = tableData.findIndex(p => (p.businessName).toLocaleLowerCase() === businessName.toLocaleLowerCase() && (p.stockistContact).toLocaleLowerCase() === stockistContact.toLocaleLowerCase() && (p.workingArea)?.toLocaleLowerCase() == city?.value  && (p.isApproved === 0 || p.isApproved === 1) );
      var mobile = tableData.findIndex(p => (p.stockistContact) === stockistContact) 
    if(index === -1 && mobile === -1){

      let error = false;
    try {
      await dispatch(
        addStockistForApproval({
          businessName,
          name,
          certification,
          gstNumber,
          drugLicenseNumber,
          drugExpiryNumber,
          establishmentDate: birthday,
          stockistContact: stockistContact,
          address: address,
          city: headquarter?.value,
          workingArea: city?.value,
          // medRepresentative: selectedUser?.value,
          medRepresentative: selectedUser?.value ? selectedUser?.value : loggedIn?.user?._id,
          extraInfo
        })
      );
      
    } catch (e) {
      error = true;
      console.log(e?.response)
      e?.response?.status === 409 ? 
          customToast.error("Stockist Already Exists"):
          customToast.error("We Encountered an Error");
    }

    if (!error) {

      customToast.success("Stockist Added Successfully");
      setBusinessName("");
      setGstNumber("");
      setDrugLicenseNumber("");
      setDrugExpiryNumber("");
      setName("");
      setCertification("");
      setBirthday("");
      setStockistContact("");
      setAddress("");
      setHeadquarter(null);
      setCity(null);
      setSelectedUser(null);
    }

    }
    else{

      if(mobile !== -1 && index === -1) customToast.error("Mobile Number Already Exists");
      else if(tableData[index].isApproved) customToast.error("Stockist Already Exists");
      else customToast.error("Stockist Awaiting Approval");
    }

    
  };

  useEffect(() => {
    try {
      viewMedRepresentatives({includeMe:true}).then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  

  return (
    <main className="main-content admin-content">
      <div className="admin-creation-content">
        <PageTitle isCreation title="Create Stockist..." />

        <section className="admin-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="businessName">
                  Business Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="businessName"
                  placeholder="Enter Stockist's Name"
                  name="businessName"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="name">
                  Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="name"
                  placeholder="Enter Stockist's Name"
                  name="name"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="certifications">
                  Certifications / Trademarks{" "}
                </label>
                <br />
                <input
                  value={certification}
                  onChange={(e) => setCertification(e.target.value)}
                  type="text"
                  className="me-5"
                  id="certifications"
                  placeholder="Enter Certification"
                  name="certifications"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="gst">GST Number </label>
                <br />
                <input
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                  type="text"
                  className="me-5"
                  id="gst"
                  placeholder="Enter GST"
                  name="gst"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="drugLicenseNumber">Drug License Number </label>
                <br />
                <input
                  value={drugLicenseNumber}
                  onChange={(e) => setDrugLicenseNumber(e.target.value)}
                  type="text"
                  className="me-5"
                  id="drugLicenseNumber"
                  placeholder="Enter Drug License Number"
                  name="drugLicenseNumber"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="drugExpiry">Drug Expiry Number </label>
                <br />
                <input
                  value={drugExpiryNumber}
                  onChange={(e) => setDrugExpiryNumber(e.target.value)}
                  type="text"
                  className="me-5"
                  id="drugExpiry"
                  placeholder="Enter Drug Expiry"
                  name="drugExpiry"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="birthday">Establishment Date</label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  name="birthday"
                />{" "}
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="contact">
                  Stockist's Contact Number{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={stockistContact}
                  onChange={(e) => setStockistContact(e.target.value)}
                  type="phone"
                  maxlength="10"
                  pattern="[0-9]*"
                  className="me-5"
                  id="contact"
                  placeholder="Enter Alternate Number"
                  name="clinicContact"
                />
              </div>
              {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.isAdmin ? (
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="mrAllot">
                    Select MR to Allot <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    name="mrAllot"
                    id="mrAllot"
                    value={selectedUser}
                    options={employeeSelect}
                    onChange={(e) => setSelectedUser({ ...e })}
                    styles={adminStyles}
                    placeholder="Select MR"
                  />
                </div>
              ) : null}
              <div className="col-sm-12 col-md-12 col-lg-12 mt-5">
                <label htmlFor="address">
                  Stockist's Address <span className="asterisk-imp">*</span>
                </label>
                <br />
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="5"
                  placeholder="Enter Clinic Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="hq">
                    Select HQ <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    name="headquarter"
                    id="headquarter"
                    value={headquarter}
                    options={hqSelect}
                    onChange={(e) =>
                      setHeadquarter({ value: e.value, label: e.label })
                    }
                    styles={adminStyles}
                    placeholder="Select Headquarter"
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="city">
                    Select Working Area <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                  name="workingArea"
                  id="workingArea"
                  value={city}
                  options={citySelect}
                  onChange={(e) => setCity({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder="Select Working Area"
                />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="extra_info">Extra Information</label>
                <br />
                <textarea
                  value={extraInfo}
                  onChange={(e) => setExtraInfo(e.target.value)}
                  type="text"
                  className="me-5"
                  id="extra_info"
                  placeholder="Enter Extra Information "
                  name="extra_info"
                  cols={30}
                  rows={1}
                ></textarea>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-4 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  Add Stockist
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default CreateStockist;
