import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/v1/entityOptions`;

export const viewEntityOptions = async (filters) => {
    console.log(filters)
    const { data } = await axios.get(`${baseURL}`, {
      params: filters,
      headers: setAuth(),
    });
    return data;
  };
  
  export const removeEntityOption = async (filters) => {
    const { data } = await axios.put(`${baseURL}/remove`, null, {
      params: filters,
      headers: setAuth(),
    });
    return data;
  };